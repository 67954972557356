@import url('https://fonts.googleapis.com/css2?family=Grandstander:wght@100;300;400;500;600;700;800;900&display=swap');

* {
    font-family: 'Grandstander', sans-serif;
    text-shadow: 2px 2px #3e3e3e!important;
}

@-ms-viewport{
    width: device-width;
}

@keyframes full-spin {
    from {
        transform: rotate3d(1, 1, 1, 200deg);
    }

    to {
        transform: rotate3d(1, 1, 1, 0deg);
    }
}

@keyframes left-31 {
    from {
        left: -50%;
    }

    to {
        left: -31%;
    }
}

@keyframes left-17 {
    from {
        left: -50%;
    }

    to {
        left: -17%;
    }
}

@keyframes left-9 {
    from {
        left: -50%;
    }

    to {
        left: -9%;
    }
}

@keyframes left-6 {
    from {
        left: -50%;
    }

    to {
        left: -6%;
    }
}

@keyframes right-13 {
    from {
        right: -50%;
    }

    to {
        right: -13%;
    }
}

@keyframes right-6 {
    from {
        right: -50%;
    }

    to {
        right: -6%;
    }
}

html, body, #root, #app {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden!important;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Grandstander', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    background-color: #5f19dd!important;
    background-image: radial-gradient(rgba(255,255,255,.1) 1px,transparent 0),radial-gradient(rgba(255,255,255,.1) 1px,transparent 0);
    background-size: 40px 40px;
    background-position: 0 0,20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f8f8f8!important;
}

#app {
    touch-action: none;
}

a {
    color: #f8f8f8!important;
    margin: 5px;
}

.ant-tooltip {
    max-width: 100%!important;
    font-size: 15px!important;
}

#background-elements {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.3));
}

#top-background-element {
    width: 100%;
    height: 20%;
    position: absolute;
    box-shadow: -1px -2px 13px rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
}

#bottom-background-element {
    width: 100%;
    height: 20%;
    position: absolute;
    box-shadow: -1px -2px 13px rgba(0, 0, 0, 0.2);
    bottom: 0;
    left: 0;
}

#rounded1 {
    background-color: #5b2bb1;
    width: 50%;
    height: 50%;
    border-radius: 100px;
    transform: rotateZ(34deg);
    position: absolute;
    top: -10%;
    clip-path: ellipse(78% 100% at 23% 90%);
    animation: left-9 0.8s ease-in-out;
    left: -9%;
}

#rounded2 {
    position: absolute;
    top: 26%;
    background-color: #5f19dd;
    width: 26%;
    height: 23%;
    border-radius: 60% 83% 142% 0%;
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
    box-shadow: 4px -1px 4px rgba(0, 0, 0, 0.1);
    animation: left-6 0.9s ease-in-out;
    left: -6%;
}

#rounded3 {
    position: absolute;
    bottom: -17%;
    background-color: #26183e;
    width: 20%;
    height: 37%;
    border-radius: 100%;
    box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.2);
    animation: right-6 0.8s ease-in-out;
    right: -6%;
}

#rounded4 {
    background-color: #5f19dd;
    width: 31%;
    height: 35%;
    border-radius: 11% 178% 23% 205%;
    -webkit-transform: rotateZ(34deg);
    transform: rotateZ(133deg);
    position: absolute;
    top: -15%;
    animation: right-13 0.6s ease-in-out;
    right: -13%;
}

#landing-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: full-spin 0.8s ease-in-out;
}

#landing-page,
#landing-page * {
    z-index: 51;
}

h1 {
    margin-bottom: 0!important;
    font-size: 48px;
    color: #f8f8f8!important;
    text-align: center;
}

#secondary {
    display: block;
    font-size: 25px;
    color: #f8f8f8;
    font-weight: 600;
}

#social-icons {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

@media (max-width: 1200px) {
    #rounded1 {
        background-color: #5b2bb1;
        width: 65%;
        height: 50%;
        border-radius: 100px;
        -webkit-transform: rotateZ(34deg);
        transform: rotateZ(34deg);
        position: absolute;
        top: -10%;
        -webkit-clip-path: ellipse(78% 100% at 23% 90%);
        clip-path: ellipse(78% 100% at 23% 90%);
        animation: left-9 0.8s ease-in-out;
        left: -9%;
    }
}

@media (max-width: 979px) {
    #rounded1 {
        background-color: #5b2bb1;
        width: 54%;
        height: 47%;
        border-radius: 100px;
        -webkit-transform: rotateZ(34deg);
        transform: rotateZ(21deg);
        position: absolute;
        top: -6%;
        -webkit-clip-path: ellipse(78% 100% at 23% 90%);
        clip-path: ellipse(78% 100% at 23% 90%);
        animation: left-9 0.8s ease-in-out;
        left: -9%;
    }

    #rounded2 {
        position: absolute;
        top: 26%;
        background-color: #5f19dd;
        width: 21%;
        height: 23%;
        border-radius: 60% 83% 142% 0%;
        -webkit-transform: rotate(20deg);
        transform: rotate(20deg);
        box-shadow: 4px -1px 4px rgba(0, 0, 0, 0.1);
        animation: left-6 0.9s ease-in-out;
        left: -6%;
    }

    #rounded3 {
        position: absolute;
        bottom: -17%;
        right: -15%;
        background-color: #26183e;
        width: 30%;
        height: 37%;
        border-radius: 100%;
        box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.2);
    }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {
    #top-background-element {
        height: 16%;
    }

    #bottom-background-element {
        height: 15%;
    }

    #rounded1 {
        background-color: #5b2bb1;
        width: 68%;
        height: 40%;
        border-radius: 100px;
        -webkit-transform: rotateZ(34deg);
        transform: rotateZ(34deg);
        position: absolute;
        top: 0%;
        left: -9%;
        -webkit-clip-path: ellipse(78% 100% at 23% 90%);
        clip-path: ellipse(78% 100% at 23% 90%);
    }

    #rounded2 {
        position: absolute;
        top: 24%;
        left: -12%;
        background-color: #5f19dd;
        width: 26%;
        height: 23%;
        border-radius: 60% 83% 142% 0%;
        -webkit-transform: rotate(20deg);
        transform: rotate(20deg);
        box-shadow: 4px -1px 4px rgba(0, 0, 0, 0.1);
    }

    #rounded3 {
        position: absolute;
        bottom: -15%;
        right: -11%;
        background-color: #26183e;
        width: 25%;
        height: 30%;
        border-radius: 100%;
        box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.2);
    }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
    #top-background-element {
        height: 15%;
    }

    #bottom-background-element {
        height: 15%;
    }

    #rounded1 {
        background-color: #5b2bb1;
        border-radius: 100px;
        -webkit-transform: rotateZ(34deg);
        transform: rotateZ(12deg);
        position: absolute;
        top: -3%;
        left: -14%;
        -webkit-clip-path: ellipse(78% 100% at 23% 90%);
        clip-path: ellipse(78% 100% at 23% 90%);
        width: 68%;
        height: 40%;
    }

    #rounded2 {
        position: absolute;
        top: 24%;
        left: -12%;
        background-color: #5f19dd;
        width: 26%;
        height: 23%;
        border-radius: 60% 83% 142% 0%;
        -webkit-transform: rotate(20deg);
        transform: rotate(20deg);
        box-shadow: 4px -1px 4px rgba(0, 0, 0, 0.1);
    }

    #rounded3 {
        position: absolute;
        bottom: -23%;
        right: -21%;
        background-color: #26183e;
        width: 42%;
        height: 37%;
        border-radius: 100%;
        box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.2);
    }
}

@media (max-width: 600px) {
    #top-background-element {
        height: 15%;
    }

    #bottom-background-element {
        height: 15%;
    }
    
    #rounded1 {
        background-color: #5b2bb1;
        width: 84%;
        height: 28%;
        border-radius: 100px;
        -webkit-transform: rotateZ(34deg);
        transform: rotateZ(34deg);
        position: absolute;
        top: 4%;
        left: -20%;
        -webkit-clip-path: ellipse(78% 100% at 23% 90%);
        clip-path: ellipse(78% 100% at 23% 90%);
    }

    #rounded2 {
        position: absolute;
        top: 24%;
        left: -11%;
        background-color: #5f19dd;
        width: 26%;
        height: 23%;
        border-radius: 60% 83% 142% 0%;
        -webkit-transform: rotate(20deg);
        transform: rotate(20deg);
        box-shadow: 4px -1px 4px rgba(0, 0, 0, 0.1);
    }

    #rounded3 {
        position: absolute;
        bottom: -23%;
        right: -25%;
        background-color: #26183e;
        width: 52%;
        height: 37%;
        border-radius: 100%;
        box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.2);
    }
}

/* Landscape phones and down */
@media (max-width: 480px) {
    #top-background-element {
        height: 20%;
    }

    #bottom-background-element {
        height: 15%;
    }

    h1 {
        font-size: 30px;
    }
    
    span {
        font-size: 20px;
    }

    svg {
        height: 24px;
        width: 24px;
    }

    #rounded1 {
        width: 110%;
        height: 45%;
        top: -10%;
        left: -31%;
        animation: left-31 0.8s ease-in-out;
    }

    #rounded2 {
        left: -17%;
        animation: left-17 0.9s ease-in-out;
    }
}

.paused-animation {
    animation-play-state: paused !important;
    animation-fill-mode: both !important;
    animation-name: none !important;
}

.running-animation {
    animation-play-state: running !important;
}

#disable-animations-zone {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 100;
}

#disable-animations-zone span {
    display: inline-block;
    margin-right: 10px;
    font-size: 15px;
    color: #f8f8f8;
    font-weight: 600;
}

.icon {
    filter: drop-shadow(2px 2px #3e3e3e);
}

.hvr-grow{display:inline-block;vertical-align:middle;-webkit-transform:perspective(1px) translateZ(0);transform:perspective(1px) translateZ(0);box-shadow:0 0 1px rgba(0,0,0,0);-webkit-transition-duration:.3s;transition-duration:.3s;-webkit-transition-property:transform;transition-property:transform}.hvr-grow:active,.hvr-grow:focus,.hvr-grow:hover{-webkit-transform:scale(1.1);transform:scale(1.1)}